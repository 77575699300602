import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'Research',
        projectDesc: 'To see our research papers click here!',
        tags: ['Research'],
        demo: ['https://doi.org/10.1016/j.ejor.2023.06.020'],
        image: one
    },
    {
        id: 2,
        projectName: 'Crowdfeeding Mobile Application',
        projectDesc: 'To see our mobile application click here!',
        tags: ['TypeScript', 'React Native', 'Mobile'],
        demo: ['https://crowdfeeding.ca/'],
        image: two
    },
    {
        id: 3,
        projectName: 'Crowdfeeding Web Application',
        projectDesc: 'To see our web application click here!',
        tags: ['TypeScript', 'React Native', 'Web'],
        demo: ['https://web.crowdfeeding.ca'],
        image: three
    },
    {
        id: 4,
        projectName: 'Video Tutorials',
        projectDesc: 'Here are some video tutorials of our application',
        tags: ['Video'],
        demo: ['https://www.youtube.com/channel/UC4jKslAsxDEqoR57_V0ZW6w'],
        image: five
    },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/