import mcmaster from '../assets/svg/skills/mcmaster-university.svg'
import expImgelkafi from '../assets/svg/experience/Elkafi-Hassini.jpg'
import expImgArmagan_Ozbilge from '../assets/svg/experience/Armagan_Ozbilge.jpeg'
import expImgsujana_saha from '../assets/svg/experience/sujana_saha.jpg'
import expImgAhana_Malhotra from '../assets/svg/experience/Ahana_Malhotra.jpeg'
import expImgChonghua from '../assets/svg/experience/chonghualiu.jpg'
import expImgRithvik from '../assets/svg/experience/rithvikb.jpg'
import expImgSaruggan from '../assets/svg/experience/saruggan.jpg'
import expImgMichael from '../assets/svg/experience/michaelbaskaran.jpg'
import expImgMehmet from '../assets/svg/experience/mehmetk.jpg'
import expImgDalia from '../assets/svg/experience/daliah.jpg'


export const experienceData = [
    // {
    //     id: 1,
    //     company: 'Globex Corporation',
    //     jobtitle: 'Frontend Developer',
    //     startYear: '2018',
    //     endYear: '2019'
    // },
    {
        id: 1,
        company: 'Dr. Elkafi Hassini',
        jobtitle: 'Professor, DeGroote School of Business, McMaster University',
        image: expImgelkafi,
    },
    {
        id: 2,
        company: 'Dr. Armagan Ozbilge',
        jobtitle: 'Assistant Professor, Faculty of Management, Dalhousie University',
        image: expImgArmagan_Ozbilge,
    },
    {
        id: 3,
        company: 'Ahana Malhotra',
        jobtitle: 'PhD Student, McMaster University',
        image: expImgAhana_Malhotra,
    },
    {
        id: 4,
        company: 'Sujana Saha',
        jobtitle: 'Master Student, McMaster University',
        image: expImgsujana_saha,
    },
    {
        id: 5,
        company: 'Chonghua Liu',
        jobtitle: 'Research Assistant, DeGroote School of Business, McMaster University',
        image: expImgChonghua,
    },
    {
        id: 6,
        company: 'Rithvik Bhogadi',
        jobtitle: 'Research Assistant, DeGroote School of Business, McMaster University',
        image: expImgRithvik,
    },
    {
        id: 7,
        company: 'Saruggan Thiruchelvan',
        jobtitle: 'Research Assistant, DeGroote School of Business, McMaster University',
        image: expImgSaruggan,
    },
    {
        id: 8,
        company: 'Michael Baskaran',
        jobtitle: 'Research Assistant, DeGroote School of Business, McMaster University',
        image: expImgMichael,
    },
    {
        id: 9,
        company: 'Mehmet Kiraz',
        jobtitle: 'Master Student, McMaster University',
        image: expImgMehmet,
    },
    {
        id: 10,
        company: 'Dalia Hassanin',
        jobtitle: 'Undergraduate Student, McMaster University',
        image: expImgDalia,
    },

]
