/* eslint-disable */
import expImgelkafi from '../assets/svg/experience/Elkafi-Hassini.jpg'
import expImgAhana_Malhotra from '../assets/svg/experience/Ahana_Malhotra.jpeg'



export const testimonialsData = [
    {
        id: 1,
        name: '',
        title: '',
        text: ""
    },
    {
        id: 2,
        name: '',
        title: '',
        text: ""
    }
]

