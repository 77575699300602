import React from 'react';
import { useHistory } from 'react-router-dom';
import './Menubar.css';

const Menubar = () => {
    const history = useHistory();

    const handleNavigation = (hash) => {
        history.push(`#${hash}`);
        document.getElementById(hash)?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="container">
            <div className="menu">
                <button onClick={() => handleNavigation('whyjoinus')}>Why Join Us</button>
                <button onClick={() => handleNavigation('screenshots')}>Screenshots</button>
                <button onClick={() => handleNavigation('projects')}>Research & Development</button>
                <button onClick={() => handleNavigation('our-partners')}>Our Partners</button>
                <button onClick={() => handleNavigation('experience')}>Meet The Team</button>
                <button onClick={() => handleNavigation('contacts')}>Contact Us</button>
            </div>
        </div>
    );
};

export default Menubar;
