import React from 'react'
import { Helmet } from 'react-helmet'

import { Navbar, Footer, Landing, About, Skills, Testimonials, Blog, Experience, Contacts, Projects, Achievement, Menubar, Education } from '../../components'
import { headerData } from '../../data/headerData'

function Main() {
    return (
        <div>
            <Helmet>
                <title>{headerData.name} - A way of crowd feeding for needy people</title>
            </Helmet>

            {/* <Navbar /> */}
            <Menubar />
            <Landing />
            <About />
            <Education/>
            <Projects />
            <Skills />
            <Experience />
            {/* <Achievement /> */}
            <Testimonials />
            <Contacts />
            <Footer />
        </div>
    )
}

export default Main
