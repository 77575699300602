import resume from '../assets/pdf/resume.pdf'

export const headerData = {
    name: '',
    // title: "Food is basic need/Welcome message",
    desciption: "Crowdfeeding is a platform that fights hunger by connecting surplus food from" 
    + " retailers to food bank users with the help of volunteer drivers. Our mission is to reduce" 
    + " food waste, alleviate food insecurity, and lower carbon footprints, promoting a sustainable" 
    + " and compassionate future.",
    // image: 'https://raw.githubusercontent.com/PhantomScript/asset-container/main/developer-portfolio/landingImg.png',
}
