export const aboutData = {
    title: {
        text: "Join Us",
        url: "https://web.crowdfeeding.ca"
    },
    title1: "Donors (Retailers): ",
    description1: "We offer support in determining the optimal time for food donations, contributing to a reduction in food wastage. Partnering with Crowdfeeding enhances your Corporate Social Responsibility (CSR) value.",
    title2: "Food Banks: ",
    description2: "Our platform ensures seamless coordination with food banks to guarantee that all clients receive food, whether through food bank programs or direct donations from retailers.",
    title3: "For Clients: ",
    description3: "If you are a registered client of a food bank that is also affiliated with Crowdfeeding, you are automatically enrolled in our program.",
    image: 2
}

