import React, { useContext } from 'react';

import './Skills.css';

import { ThemeContext } from '../../contexts/ThemeContext';
import { skillsData } from '../../data/skillsData';
import { skillsImage } from '../../utils/skillsImage';

function Skills() {
    const { theme } = useContext(ThemeContext);

    const skillBoxStyle = {
        backgroundColor: theme.secondary,
        boxShadow: `0px 0px 30px ${theme.primary30}`,
        textAlign: 'center',
    };

    return (
        <div className="skills" id="our-partners" style={{ backgroundColor: theme.secondary }}>
            <div className="skillsHeader">
                <h2 style={{ color: theme.primary }}>Our Partners</h2>
            </div>
            <div className="skillsContainer">
                <div className="skill--scroll">
                    {skillsData.map((skill, id) => (
                        <a href={skill.url} target="_blank" rel="noopener noreferrer" key={id} style={{ textDecoration: 'none' }}>
                            <div className="skill--box" style={skillBoxStyle}>
                                <img src={skillsImage(skill.name)} alt={skill.name} />
                                <h3 style={{ color: theme.tertiary }}>
                                    {skill.name}
                                </h3>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Skills;
