import React, { useContext } from 'react';
import './About.css';
import { ThemeContext } from '../../contexts/ThemeContext';
import { aboutData } from '../../data/aboutData';

function About() {
    const { theme } = useContext(ThemeContext);

    return (
        <div className="whyjoinus" id="whyjoinus" style={{ backgroundColor: theme.secondary }}>
            <div className="line-styling">
                <div className="style-circle" style={{ backgroundColor: theme.primary }}></div>
                <div className="style-circle" style={{ backgroundColor: theme.primary }}></div>
                <div className="style-line" style={{ backgroundColor: theme.primary }}></div>
            </div>
            <div className="whyjoinus-body">
                <div className="whyjoinus-description">
                    <h2 style={{ color: theme.primary }}>
                        <a href={aboutData.title.url} style={{ color: theme.primary }} target="_blank" rel="noopener noreferrer">
                            {aboutData.title.text}
                        </a>
                    </h2>
                    <p style={{ color: theme.tertiary80 }}>
                        <strong>{aboutData.title1}</strong>{aboutData.description1}<br /><br />
                        <strong>{aboutData.title2}</strong>{aboutData.description2}<br /><br />
                        <strong>{aboutData.title3}</strong>{aboutData.description3}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default About;
